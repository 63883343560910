import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import gql from 'graphql-tag';
import CompleteSimpleLinkBlock from '@fragment/shared/CompleteSimpleLinkBlock';

export default gql`
    fragment CompleteTileBlock on TileBlock {
        id
        blockType
        logo {
            ...CompleteCustomImage
        }
        content
        link {
            ...CompleteSimpleLinkBlock
        }
    }
    ${CompleteCustomImage}
    ${CompleteSimpleLinkBlock}
`;
