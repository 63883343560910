import CompleteVideoBlock from '@fragment/shared/CompleteVideoBlock';
import gql from 'graphql-tag'

export default gql`
    fragment CompleteHeroVideoTier on HeroVideoTier {
        blockType
        video {
            ...CompleteVideoBlock
        }
        autoplay
        showControls
        hasAudio
    }
    ${CompleteVideoBlock}
`;
