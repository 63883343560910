import CompleteContentGlobal from '@fragment/shared/CompleteContentGlobal';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteGlobalTier on GlobalTier {
        id
        globals {
        ... on SnippetBlock {
            snippet {
                ...CompleteContentGlobal
            }
        }
        }
    }
    ${CompleteContentGlobal}
`;
