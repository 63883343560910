import gql from 'graphql-tag';

export default gql`
    fragment CompleteTableBlock on TableBlock {
        id
        blockType
        tableStyle
        topRowText
        header
        table {
            heading
            rows
        }
        tableSummary
        tableCaption
        tableMinimumWidth
        columnWidths
        tableHighlightColor
    }
`;
