export default {
    props: {
        level: {
            type: [Number, String],
            default: 2,
            validator: (value) => {
                const num = Number(value);
                return 1 <= num && num <= 6;
            },
        },
    },
};
