import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompleteFloatingImageBlock from '@fragment/shared/CompleteFloatingImageBlock';
import DefaultBlocks from '@fragment/shared/DefaultBlocks';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteOneColumnTier on OneColumnTier {
        id
        blockType
        blockTail
        blockStyle
        blockFormat
        highlight
        backgroundSplit
        backgroundImage {
            ...CompleteCustomImage
        }
        floatingImage {
            ...CompleteFloatingImageBlock
        }
        verticalAlignment
        centerColumn {
            ...DefaultBlocks
        }
    }
    ${CompleteCustomImage}
    ${CompleteFloatingImageBlock}
    ${DefaultBlocks}
`;
