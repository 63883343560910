<template>
    <component :is="`h${level}`" class="header-2">
        <slot />
    </component>
</template>

<script>
import level from '@mixin/level';
export default {
    name: 'Header2',
    mixins: [level],
};
</script>
