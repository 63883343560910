import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompleteCardBlock from '@fragment/shared/CompleteCardBlock';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteContentCardsTier on ContentCardsTier {
        id
        blockTail
        blockStyle
        blockType
        backgroundSplit
        backgroundImage {
            ...CompleteCustomImage
        }
        header
        dek
        cornerCutout
        highlight
        contentCards {
            ...CompleteCardBlock
        }
    }
    ${CompleteCustomImage}
    ${CompleteCardBlock}
`;
