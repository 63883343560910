import PagePreview from '@fragment/previews/PagePreview';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteSimpleLinkBlock on SimpleLinkBlock {
        blockType
        linkTo
        anchor
        newWindow
        customUrl
        page {
            ...PagePreview
        }
        file {
            id
            file
            url
        }
    }
    ${PagePreview}
`;
