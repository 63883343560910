import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompleteFloatingImageBlock from '@fragment/shared/CompleteFloatingImageBlock';
import CompleteColumnTierColumn from '@fragment/shared/CompleteColumnTierColumn';
import gql from 'graphql-tag'

export default gql`
    fragment CompleteTwoColumnTier on TwoColumnTier {
        id
        blockTail
        blockStyle
        blockType
        header
        dek
        backgroundSplit
        backgroundImage {
            ...CompleteCustomImage
        }
        floatingImage {
            ...CompleteFloatingImageBlock
        }
        displayType
        paddingStyle
        rightColumn {
            ...CompleteColumnTierColumn
        }
        leftColumn {
            ...CompleteColumnTierColumn
        }
        linkingIcon {
            ...CompleteCustomImage
        }
    }
    ${CompleteCustomImage}
    ${CompleteFloatingImageBlock}
    ${CompleteColumnTierColumn}
`;
