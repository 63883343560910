import CompleteTileBlock from '@fragment/shared/CompleteTileBlock';
import CompleteNumberTileBlock from '@fragment/shared/CompleteNumberTileBlock';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteNumberTileTier on NumberTileTier {
        blockType
        highlight
        groupBy
        leftHeader
        description
        tiles {
            ...CompleteNumberTileBlock
        }
    }
    ${CompleteTileBlock}
    ${CompleteNumberTileBlock}
`;
