import CompleteCTABlock from '@fragment/shared/CompleteCTABlock';
import CompleteCustomForm from '@fragment/pages/CompleteCustomForm';
import CompleteRichTextContentBlock from '@fragment/shared/CompleteRichTextContentBlock';
import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import gql from 'graphql-tag'

export default gql`
    fragment CompleteFormTier on FormTier {
        blockType
        blockStyleTop
        header
        dek
        cta {
            ...CompleteCTABlock
        }
        form {
            ...CompleteCustomForm
        }
        footer {
            ...CompleteRichTextContentBlock
        }
        backgroundImage {
            ...CompleteCustomImage
        }
    }
    ${CompleteCTABlock}
    ${CompleteCustomForm}
    ${CompleteRichTextContentBlock}
    ${CompleteCustomImage}
`;
