import gql from 'graphql-tag'

export default gql`
    fragment CompleteVideoChooserBlock on VideoChooserBlock {
        blockType
        video {
            id
            url
            title
            width
            height
            duration
        }
    }
`;
