import gql from 'graphql-tag'

export default gql`
    fragment CompleteRatingsChartBlock on RatingsChartBlock {
        blockType
        ratings {
            ... on RatingBlock {
                name
                value
                low
                mid
                high
            }
        }
    }
`;
