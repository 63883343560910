import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompleteColorBlock from '@fragment/shared/CompleteColorBlock';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteColorBlocksTier on ColorBlocksTier {
        blockType
        linkingIcon {
            ...CompleteCustomImage
        }
        blockTail
        blockTriangle
        colorBlocks {
            ...CompleteColorBlock
        }
    }
    ${CompleteCustomImage}
    ${CompleteColorBlock}
`;
