import CompleteCustomForm from '@fragment/pages/CompleteCustomForm';
import gql from 'graphql-tag'

export default gql`
    fragment CompleteFormBlock on FormBlock {
        id
        blockType
        form {
            ...CompleteCustomForm
        }
    }
    ${CompleteCustomForm}
`;
