import CompleteDataVizDatasetBlock from '@fragment/shared/CompleteDataVizDatasetBlock';
import gql from 'graphql-tag'

export default gql`
    fragment CompleteBarGraphBlock on BarGraphBlock {
        blockType
        direction
        min
        max
        datasets {
            ...CompleteDataVizDatasetBlock
        }
    }
    ${CompleteDataVizDatasetBlock}
`;
