import CompleteAccordionBlock from '@fragment/shared/CompleteAccordionBlock';
import CompleteCardBlock from '@fragment/shared/CompleteCardBlock';
import CompleteCarouselBlock from '@fragment/shared/CompleteCarouselBlock';
import CompleteCTABlock from '@fragment/shared/CompleteCTABlock';
import CompleteDataVizBlock from '@fragment/shared/CompleteDataVizBlock';
import CompleteFormBlock from '@fragment/shared/CompleteFormBlock';
import CompleteHeroTier from '@fragment/shared/CompleteHeroTier';
import CompleteImageBlock from '@fragment/shared/CompleteImageBlock';
import CompleteRelatedListBlock from '@fragment/shared/CompleteRelatedListBlock';
import CompleteRichTextContentBlock from '@fragment/shared/CompleteRichTextContentBlock';
import CompleteVideoBlock from '@fragment/shared/CompleteVideoBlock';
import CompleteTableBlock from '@fragment/shared/CompleteTableBlock';
import gql from 'graphql-tag'

export default gql`
    fragment DefaultBlocks on StreamFieldInterface {
        id
        blockType
        ...CompleteAccordionBlock
        ...CompleteCardBlock
        ...CompleteCarouselBlock
        ...CompleteCTABlock
        ...CompleteDataVizBlock
        ...CompleteFormBlock
        ...CompleteHeroTier
        ...CompleteImageBlock
        ...CompleteRelatedListBlock
        ...CompleteRichTextContentBlock
        ...CompleteVideoBlock
        ...CompleteTableBlock
    }
    ${CompleteAccordionBlock}
    ${CompleteCardBlock}
    ${CompleteCarouselBlock}
    ${CompleteCTABlock}
    ${CompleteDataVizBlock}
    ${CompleteFormBlock}
    ${CompleteHeroTier}
    ${CompleteImageBlock}
    ${CompleteRelatedListBlock}
    ${CompleteRichTextContentBlock}
    ${CompleteVideoBlock}
    ${CompleteTableBlock}
`;
