import gql from 'graphql-tag';

export default gql`
    fragment CompleteCustomImageObjectType on CustomImageObjectType {
        id
        url
        focalPointX
        focalPointY
        focalPointWidth
        focalPointHeight
        tags {
            id
            name
        }
        title
        width
        height
        altText
        attribution
    }
`;
