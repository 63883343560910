import CompleteImageBlock from '@fragment/shared/CompleteImageBlock';
import CompleteVideoBlock from '@fragment/shared/CompleteVideoBlock';
import CompleteHeroVideoTier from '@fragment/shared/CompleteHeroVideoTier';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteMediaTier on MediaTier {
        media {
            ...CompleteImageBlock
            ...CompleteVideoBlock
            ...CompleteHeroVideoTier
        }
    }
    ${CompleteImageBlock}
    ${CompleteVideoBlock}
    ${CompleteHeroVideoTier}
`;
