import CompleteCarouselSlide from '@fragment/shared/CompleteCarouselSlide';
import gql from 'graphql-tag'

export default gql`
    fragment CompleteCarouselBlock on CarouselBlock {
        blockType
        title
        subtitle
        slides {
            ...CompleteCarouselSlide
        }
    }
    ${CompleteCarouselSlide}
`;
