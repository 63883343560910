import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteCustomForm on CustomForm {
        id
        noIndex
        access
        title
        formUrl
        isModal
        seoOgImage {
            ...CompleteCustomImage
        }
    }
    ${CompleteCustomImage}
`;
