import CompleteImageBlock from '@fragment/shared/CompleteImageBlock';
import CompletePageChooserBlock from '@fragment/shared/CompletePageChooserBlock';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteGalleryTier on GalleryTier {
        header
        description
        items {
            ...CompleteImageBlock
            ...CompletePageChooserBlock
        }
    }
    ${CompleteImageBlock}
    ${CompletePageChooserBlock}
`;
