import CompleteTileBlock from '@fragment/shared/CompleteTileBlock';
import CompleteNumberTileBlock from '@fragment/shared/CompleteNumberTileBlock';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteTileTier on TileTier {
        blockType
        highlight
        groupBy
        leftHeader
        description
        tiles {
            ...CompleteTileBlock
        }
    }
    ${CompleteTileBlock}
    ${CompleteNumberTileBlock}
`;
