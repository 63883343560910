import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompleteVideoBlock from '@fragment/shared/CompleteVideoBlock';
import CompleteCTABlock from '@fragment/shared/CompleteCTABlock';
import gql from 'graphql-tag'

export default gql`
    fragment CompleteHeroTier on HeroTier {
        id
        blockTail
        blockStyle
        blockType
        backgroundImage {
            ...CompleteCustomImage
        }
        backgroundVideo {
            ...CompleteVideoBlock
        }
        type
        header
        dek
        cta {
            ...CompleteCTABlock
        }
    }
    ${CompleteCustomImage}
    ${CompleteVideoBlock}
    ${CompleteCTABlock}
`;
