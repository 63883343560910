import CompletLineGraphBlock from '@fragment/shared/CompletLineGraphBlock';
import CompleteBarGraphBlock from '@fragment/shared/CompleteBarGraphBlock';
import CompletePieGraphBlock from '@fragment/shared/CompletePieGraphBlock';
import CompleteRatingsChartBlock from '@fragment/shared/CompleteRatingsChartBlock';
import CompleteLinkBlock from '@fragment/shared/CompleteLinkBlock';
import gql from 'graphql-tag'

export default gql`
    fragment CompleteDataVizBlock on DataVizBlock {
        blockType
        title
        dataVisualization {
            __typename
            ...CompletLineGraphBlock
            ...CompleteBarGraphBlock
            ...CompletePieGraphBlock
            ...CompleteRatingsChartBlock
        }
        caption
        link {
            ...CompleteLinkBlock
        }
    }
    ${CompletLineGraphBlock}
    ${CompleteBarGraphBlock}
    ${CompletePieGraphBlock}
    ${CompleteRatingsChartBlock}
    ${CompleteLinkBlock}
`;
