import gql from 'graphql-tag'

export default gql`
    fragment CompleteDataVizAxisBlock on DataVizAxisBlock {
        blockType
        name
        low
        high
        labels {
            ... on DataVizAxisLabelBlock {
                name
                location
            }
        }
    }
`;
