import CompleteLinkBlock from '@fragment/shared/CompleteLinkBlock';
import gql from 'graphql-tag'

export default gql`
    fragment CompleteCTABlock on CTABlock {
        blockType
        id
        type
        float
        link {
            ...CompleteLinkBlock
        }
    }
    ${CompleteLinkBlock}
`;
