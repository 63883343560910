import gql from 'graphql-tag'

export default gql`
    fragment CompleteDataVizDatasetBlock on DataVizDatasetBlock {
        blockType
        name
        color
        dataPoints
    }
`;
