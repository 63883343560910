import CompleteRichTextContentBlock from '@fragment/shared/CompleteRichTextContentBlock';
import CompleteImageChooserBlock from '@fragment/shared/CompleteImageChooserBlock';
import CompleteDataVizBlock from '@fragment/shared/CompleteDataVizBlock';
import CompleteCTABlock from '@fragment/shared/CompleteCTABlock';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteCardBlock on CardBlock {
        backgroundColor
        cardContent {
            ...CompleteRichTextContentBlock
            ...CompleteImageChooserBlock
            ...CompleteDataVizBlock
            ...CompleteCTABlock
        }
        caption
    }
    ${CompleteRichTextContentBlock}
    ${CompleteImageChooserBlock}
    ${CompleteDataVizBlock}
    ${CompleteCTABlock}
`;
