import CompletePageChooserBlock from '@fragment/shared/CompletePageChooserBlock';
import CompleteImageChooserBlock from '@fragment/shared/CompleteImageChooserBlock';
import gql from 'graphql-tag'

export default gql`
    fragment CompleteCarouselSlide on CarouselBlockSlide {
        slide {
            ...CompletePageChooserBlock
            ...CompleteImageChooserBlock
        }
        caption
    }
    ${CompletePageChooserBlock}
    ${CompleteImageChooserBlock}
`;
