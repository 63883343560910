import SingleTiers from '@fragment/shared/SingleTiers';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteContentGlobal on ContentGlobal {
        title
        content {
            ...SingleTiers
        }
    }
    ${SingleTiers}
`;
