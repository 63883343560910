import CompleteRelatedListBlock from '@fragment/shared/CompleteRelatedListBlock';
import CompletePageChooserBlock from '@fragment/shared/CompletePageChooserBlock';
import CompleteRichTextContentBlock from '@fragment/shared/CompleteRichTextContentBlock';
import CompleteTableBlock from '@fragment/shared/CompleteTableBlock';
import CompletePodcastBlock from '@fragment/shared/CompletePodcastBlock';
import CompleteCarouselBlock from '@fragment/shared/CompleteCarouselBlock';
import CompleteVideoBlock from '@fragment/shared/CompleteVideoBlock';
import gql from 'graphql-tag'

export default gql`
    fragment CompleteSectionTier on SectionTier {
        id
        blockType
        title
        navigationLabel
        blocks {
            ...CompleteRelatedListBlock
            ...CompletePageChooserBlock
            ...CompleteRichTextContentBlock
            ...CompleteTableBlock
            ...CompletePodcastBlock
            ...CompleteCarouselBlock
            ...CompleteVideoBlock
        }
    }
    ${CompleteRelatedListBlock}
    ${CompletePageChooserBlock}
    ${CompleteRichTextContentBlock}
    ${CompleteTableBlock}
    ${CompletePodcastBlock}
    ${CompleteCarouselBlock}
    ${CompleteVideoBlock}
`;
