import CompleteDataVizDatasetBlock from '@fragment/shared/CompleteDataVizDatasetBlock';
import gql from 'graphql-tag'

export default gql`
    fragment CompletePieGraphBlock on PieGraphBlock {
        blockType
        datasets {
            ...CompleteDataVizDatasetBlock
        }
    }
    ${CompleteDataVizDatasetBlock}
`;
