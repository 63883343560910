import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import gql from 'graphql-tag'

export default gql`
    fragment CompleteImageBlock on ImageBlock {
        blockType
        id
        altText
        caption
        image {
            ...CompleteCustomImage
        }
    }
    ${CompleteCustomImage}
`;
