import gql from 'graphql-tag'

export default gql`
    fragment CompletePodcastBlock on PodcastBlock {
        blockType
        title
        subtitle
        embedUrl
        embedCode
    }
`;
