import gql from 'graphql-tag';
import CompleteSimpleLinkBlock from '@fragment/shared/CompleteSimpleLinkBlock';

export default gql`
    fragment CompleteNumberTileBlock on NumberTileBlock {
        id
        blockType
        content
        link {
            ...CompleteSimpleLinkBlock
        }
    }

    ${CompleteSimpleLinkBlock}
`;
