import CompleteDataVizAxisBlock from '@fragment/shared/CompleteDataVizAxisBlock';
import CompleteDataVizDatasetLabeledBlock from '@fragment/shared/CompleteDataVizDatasetLabeledBlock';
import gql from 'graphql-tag'

export default gql`
    fragment CompletLineGraphBlock on LineGraphBlock {
        blockType
        xAxis {
            ...CompleteDataVizAxisBlock
        }
        yAxis {
            ...CompleteDataVizAxisBlock
        }
        datasets {
            ...CompleteDataVizDatasetLabeledBlock
        }
    }
    ${CompleteDataVizAxisBlock}
    ${CompleteDataVizDatasetLabeledBlock}
`;
