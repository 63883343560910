import CompleteVideoChooserBlock from '@fragment/shared/CompleteVideoChooserBlock';
import CompleteVideoEmbedBlock from '@fragment/shared/CompleteVideoEmbedBlock';
import gql from 'graphql-tag'

export default gql`
    fragment CompleteVideoBlock on VideoBlock {
        id
        blockType
        videoBlock: video {
            ...CompleteVideoChooserBlock
            ...CompleteVideoEmbedBlock
        }
        caption
        transcript
    }
    ${CompleteVideoChooserBlock}
    ${CompleteVideoEmbedBlock}
`;
