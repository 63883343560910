import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import gql from 'graphql-tag'

export default gql`
    fragment CompleteFloatingImageBlock on FloatingImageBlock {
        blockType
        id
        altText
        image {
            ...CompleteCustomImage
        }
        overlap
        placement
    }
    ${CompleteCustomImage}
`;
