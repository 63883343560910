import CompleteHeroTier from '@fragment/shared/CompleteHeroTier';
import CompleteOneColumnTier from '@fragment/shared/CompleteOneColumnTier';
import CompleteTwoColumnTier from '@fragment/shared/CompleteTwoColumnTier';
import CompleteContentCardsTier from '@fragment/shared/CompleteContentCardsTier';
import CompleteRelatedTier from '@fragment/shared/CompleteRelatedTier';
import CompleteColorBlocksTier from '@fragment/shared/CompleteColorBlocksTier';
import CompleteFormTier from '@fragment/shared/CompleteFormTier';
import CompleteNumberTileTier from '@fragment/shared/CompleteNumberTileTier';
import CompleteTileTier from '@fragment/shared/CompleteTileTier';
import CompleteMediaTier from '@fragment/shared/CompleteMediaTier';
import CompleteFeaturedResourceTier from '@fragment/shared/CompleteFeaturedResourceTier';
import CompleteSectionTier from '@fragment/shared/CompleteSectionTier';
import CompleteGalleryTier from '@fragment/shared/CompleteGalleryTier';
import gql from 'graphql-tag';

export default gql`
    fragment SingleTiers on StreamFieldInterface {
        id
        blockType
        ...CompleteHeroTier
        ...CompleteOneColumnTier
        ...CompleteTwoColumnTier
        ...CompleteContentCardsTier
        ...CompleteRelatedTier
        ...CompleteColorBlocksTier
        ...CompleteFormTier
        ...CompleteNumberTileTier
        ...CompleteTileTier
        ...CompleteMediaTier
        ...CompleteFeaturedResourceTier
        ...CompleteSectionTier
        ...CompleteGalleryTier
    }
    ${CompleteHeroTier}
    ${CompleteOneColumnTier}
    ${CompleteTwoColumnTier}
    ${CompleteContentCardsTier}
    ${CompleteRelatedTier}
    ${CompleteColorBlocksTier}
    ${CompleteFormTier}
    ${CompleteNumberTileTier}
    ${CompleteTileTier}
    ${CompleteMediaTier}
    ${CompleteFeaturedResourceTier}
    ${CompleteSectionTier}
    ${CompleteGalleryTier}
`;
