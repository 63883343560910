import CompleteRichTextContentBlock from '@fragment/shared/CompleteRichTextContentBlock';
import CompleteTableBlock from '@fragment/shared/CompleteTableBlock';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteAccordionBlock on AccordionBlock {
        blockType
        id
        highlightType
        highlightColor
        heading
        AccordionBlock: content {
            ...CompleteRichTextContentBlock
            ...CompleteTableBlock
        }
    }
    ${CompleteRichTextContentBlock}
    ${CompleteTableBlock}
`;
